// ... your code that runs before foundation initiation

// Fix for foundation stylesheets being picked up as "null" or "not an object",
// implementation from here: http://foundation.zurb.com/forum/posts/3189-foundation-5-orbit-slider-ie8-issue
// (function($) {
//     'use strict';

//     if (!Foundation.stylesheet) {
//         Foundation._style_element = $('<style></style>').appendTo('head')[0];
//         Foundation.stylesheet = Foundation._style_element.styleSheet;

//         if (Foundation.stylesheet) {
//             Foundation.stylesheet.cssRules = {
//                 length: 0
//             };

//             Foundation.stylesheet.insertRule = function(rule, index) {
//                 var media;
//                 var mediaMatch;
//                 var mediaRegex;
//                 var namespace;
//                 var ruleMatch;
//                 var ruleRegex;
//                 mediaRegex = /^\s*@media\s*(.*?)\s*\{\s*(.*?)\s*\}\s*$/;
//                 mediaMatch = mediaRegex.exec(rule);
//                 media = '';

//                 if (mediaMatch) {
//                     media = '@media ' + mediaMatch[1] + ' ';
//                     rule = mediaMatch[2];
//                 }

//                 ruleRegex = /^\s*(.*?)\s*\{\s*(.*?)\s*\}\s*$/;
//                 ruleMatch = ruleRegex.exec(rule);
//                 namespace = '' + media + ruleMatch[1];
//                 rule = ruleMatch[2];

//                 return this.addRule(namespace, rule);
//             };
//         } else if (window.console) {
//             console.log('Could not fix Foundation CSS rules...');
//         }
//     }
// })(jQuery);

// Accessible image sprites (modificat):
// https://www.paciellogroup.com/blog/2012/08/notes-on-accessible-css-image-sprites/

function HCTest() {
    'use strict';

    var objDiv;
    var strColor;
    var objFlag;

    // reference to img element used to check if images are disabled
    objFlag = document.getElementById('flag');
    if (objFlag === null) {
        return;
    }

    // Create a test div
    objDiv = document.createElement('div');

    // Set its color style to something unusual
    objDiv.style.color = 'rgb(31,41,59)';

    // Attach to body so we can inspect it
    document.body.appendChild(objDiv);

    // Use standard means if available, otherwise use the IE methods

    strColor = document.defaultView ? document.defaultView.getComputedStyle(objDiv, null).color : objDiv.currentStyle.color;

    // get rid of extra spaces in result
    // strColor = strColor.replace(/ /g,"");

    // Delete the test DIV

    document.body.removeChild(objDiv);

    // Check if we got back what we set (strColor== ??) If not we are in high contrast mode
    // Use .offsetwidth and .readyState (for IE) to check if images are enabled
    // If either images are disabled or high contrast is enabled (or both) the CSS stylesheet link will not be added to the page and the visually hidden text will be displayed in place of the missing background image

    if (!(((objFlag.offsetWidth === 1 && objFlag.readyState === 'complete') || (objFlag.offsetWidth === 1 && objFlag.readyState === undefined)) && (strColor === 'rgb(31,41,59)' || strColor === 'rgb(31, 41, 59)'))) {

        var objHead = document.getElementsByTagName('head');
        var objCSS = objHead[0].appendChild(document.createElement('link'));
        objCSS.rel = 'stylesheet';
        objCSS.href = '/media/css/alt.css';
        // objCSS.type = 'text/css';
    }
}

// Safely initiate foundation now
$(document).foundation();

// the rest of your code

$(window).load(function() {
    'use strict';

    // accessible image sprites
    HCTest();
});

// mobile-nav
(function() {

    var mqm = window.matchMedia('(max-width: 64.0625em)');

    // get the button and menu nodes
    var button = document.querySelector('[aria-label="site"] button');
    var menu = button.nextElementSibling;

    // set initial (closed menu) states
    button.setAttribute('aria-expanded', 'false');
    button.hidden = false;
    if (mqm.matches) {
        menu.hidden = true;
    }

    $( window ).resize(function() {
        if (window.matchMedia('(min-width: 620px)').matches) {
            menu.hidden = false;
        }
    });

    button.addEventListener('click', function() {
        // toggle menu visibility
        var expanded = this.getAttribute('aria-expanded') === 'true';
        this.setAttribute('aria-expanded', String(!expanded));
        menu.hidden = expanded;
    });

})();

$(document).ready(function() {
    'use strict';

    // feature.js
    if (feature.svg) {
        $('html').addClass('svg');
    }

    // hamburger
    var $hamburger = $(".hamburger");
    $hamburger.on("click", function(e) {
      $hamburger.toggleClass("is-active");
      $('.menu').toggleClass("js-open");
      $('body').toggleClass("js-menu-open");
      // canvi logo
      if ($('body').hasClass('js-menu-open')) {
        if ($('body').hasClass('has-bg')) {
          $('.site-title img').attr('src','/media/img/logo-bancsang-negatiu.png');
          $('.site-title img').attr('srcset','/media/img/logo-bancsang-negatiu@2x.png 2x, /media/img/logo-bancsang-negatiu@3x.png 3x');
        }
        else {
          $('.site-title img').attr('src','/media/img/logo-bancsang-negatiu.png');
          $('.site-title img').attr('srcset','/media/img/logo-bancsang-negatiu@2x.png 2x, /media/img/logo-bancsang-negatiu@3x.png 3x');
        }
      }
      else {
        if ($('body').hasClass('has-bg')) {
          $('.site-title img').attr('src','/media/img/logo-bancsang-negatiu.png');
          $('.site-title img').attr('srcset','/media/img/logo-bancsang-negatiu@2x.png 2x, /media/img/logo-bancsang-negatiu@3x.png 3x');
        }
        else {
          $('.site-title img').attr('src','/media/img/logo-bancsang-positiu.png');
          $('.site-title img').attr('srcset','/media/img/logo-bancsang-positiu@2x.png 2x, /media/img/logo-bancsang-positiu@3x.png 3x');
        }
      }
    });

    // skip link (https://www.bignerdranch.com/blog/web-accessibility-skip-navigation-links/)
    // bind a click event to the 'skip' link
    $(".skip-link").click(function(event){

        // strip the leading hash and declare
        // the content we're skipping to
        var skipTo="#"+this.href.split('#')[1];

        // Setting 'tabindex' to -1 takes an element out of normal
        // tab flow but allows it to be focused via javascript
        $(skipTo).attr('tabindex', -1).on('blur focusout', function () {

            // when focus leaves this element,
            // remove the tabindex attribute
            $(this).removeAttr('tabindex');

        }).focus(); // focus on the content container
    });

    // Match Height
    $('.locations div').matchHeight();
    $('.maratons__wrapper').matchHeight();
    $('.collab-box').matchHeight();
    $('.text-wrapper').matchHeight();

    // canvi svg per png
    if (!feature.svg) {
        var imgs = document.getElementsByTagName('img');
        var endsWithDotSvg = /.*\.svg$/;
        var i = 0;
        var l = imgs.length;
        for (; i !== l; ++i) {
            if (imgs[i].src.match(endsWithDotSvg)) {
                imgs[i].src = imgs[i].src.slice(0, -3) + 'png';
            }
        }
    }

    // smooth scroll top
    $(window).scroll(function() {
      if ($(this).scrollTop() > 300) {
          $(".top").show();
          $(".menu-fixed").addClass('open');
      } else {
          $(".top").hide();
          $(".menu-fixed").removeClass('open');
      }
    });
    $(function() {
      $('a[href*="#"]:not([href="#"],[href="#s"])').click(function() {
        if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
          var target = $(this.hash);
          target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
          if (target.length) {
            $('html, body').animate({
              scrollTop: target.offset().top
            }, 1000);
            return false;
          }
        }
      });
    });

    // sliders
    $('.slider').slick({
        dots: true,
        autoplay: true,
        autoplaySpeed: 12000
    });

    // video home
    //$(".splash").fitVids();


    // Progressive collapsibles
    $('.collapsible__title').each(function() {

      var $this = $(this);

      // create unique id for a11y relationship

      var id = 'collapsible-' + $this.index();

      // wrap the content and make it focusable

      $this.nextUntil('.collapsible__title').wrapAll('<div class="collapsible__body id="'+ id +'" aria-hidden="true">');
      var panel = $this.next();

      // Add the button inside the <h2> so both the heading and button semantics are read

      $this.wrapInner('<button aria-expanded="false" aria-controls="'+ id +'">');
      var button = $this.children('button');

      // Toggle the state properties

      button.on('click', function() {
        var state = $(this).attr('aria-expanded') === 'false' ? true : false;
        $(this).attr('aria-expanded', state);
        panel.attr('aria-hidden', !state);
        $(this).parent().toggleClass('js-open');
        $(this).parent().parent().toggleClass('js-open');
      });

    });

    // alert
    var alertBtn = $('.alert').children('button');
    alertBtn.on('click', function() {
      var state = $(this).attr('aria-expanded') === 'false' ? true : false;
      $(this).attr('aria-expanded', state);
      $(this).parent().toggleClass("js-closed");
    });

    //test
    function auto_load(){
        var formData = $("#myForm").serialize();
        $.ajax({
          url: urlTest,
          cache: false,
          data: formData,
          type: "POST",
          success: function(data){
            var pas = $("#pregunta").val();
             if($("#properaPregunta").length > 0){
                $("#pregunta-num").text($("#properaPregunta").val());
             }
             $("#page").html(data);
             var src = $("#pas"+pas).attr('src');
             $("#pas"+pas).attr('src', src.replace('negre', 'blanc'));
             $("#pas"+pas).addClass('completat');
             $("#pas"+pas).addClass('carregat');
          }
        });

    }
    if($(".test-container").length > 0){
      $(document).on('submit','form', function( event ) {
               event.preventDefault();

              $( "form" ).removeClass('navInNext').addClass('navOutNext');
              setTimeout(function(){
                  auto_load();
              }, 750);
              return false;
      });
      $(document).on('change','form input[type=radio]', function( event ) {
             $( "#myForm" ).trigger( "submit" );
      });
      $('#start').on('click', function() {
         $('#siteloader').load(urlTest);
         $('#test-rapid').addClass('test-container--move');
           $("html, body").animate({ scrollTop: 0 }, 1000);
          return false;

      });
      $('#tancar-joc').on('click', function() {
         $('#test-rapid').removeClass('test-container--move');
         return false;
      });
    }

});
